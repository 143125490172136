import { Injectable } from '@angular/core';
import { DataService } from '../shared/data.service';
import { resolve } from 'url';
import { reject } from 'q';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  constructor(
    private dataService : DataService
  ) { }



  getProjects = (condition = {}, options = {}) => {

    return new Promise((resolve,reject) => {
      
      this.dataService.__post('/projects/get',{ condition : condition, options : options })
      .subscribe(
        (projects) => {
          resolve(projects)
        },
        error => {
          reject(error)
        }
      )

    })

  }

  getProject = (condition) => {

    return new Promise((resolve,reject) => {
      
      this.dataService.__post('/projects/get/one',condition)
      .subscribe(
        (project) => {
          resolve(project)
        },
        error => {
          reject(error)
        }
      )

    })

  }

  getCategories = (condition = {}) => {

    return new Promise((resolve,reject) => {
      
      this.dataService.__post('/categories/get',condition)
      .subscribe(
        (projects) => {
          resolve(projects)
        },
        error => {
          reject(error)
        }
      )

    })

  }

}
