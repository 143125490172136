import { Component, OnInit } from '@angular/core';
import { PortfolioService } from 'src/app/portfolio/portfolio.service';
import Project from 'src/app/portfolio/project.modal';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/shared/data.service';
import Service from '../service.modal';

declare var  PIXELSIGNS :any;

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent implements OnInit {


  projects : Array<Project> = [];
  service : Service;
  serviceID : string = '';

  constructor(
    private dataService : DataService,
    private portfolioService : PortfolioService,
    private activatedRoute : ActivatedRoute
  ) { }

  ngOnInit() {

    this.serviceID = this.activatedRoute.snapshot.paramMap.get('id');
    this._getService();
    this._getProjects();

  }


  _getService(){

    this.dataService.__post('/get/service',{service : this.serviceID})
    .subscribe(
      (service : Service) => {
        this.service = service
      },

      (error) => {

      }
    )


  }


  _getProjects = () => {
    
    this.portfolioService.getProjects({service : this.serviceID})
    .then(( projects : Array<Project> ) => {

      projects.map(( project ) => {
        this.projects.push( new Project(project) )
      });

      PIXELSIGNS.initialize.portfolio();

      setTimeout(() => {
        PIXELSIGNS.initialize.portfolio();
      },1000)

    })
    .catch((error) => {
      console.log(error)
    })

  }

}
