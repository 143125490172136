import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PortfolioService } from './portfolio.service';
import Project from './project.modal';
import Category from './category.modal';
import { DataService } from '../shared/data.service';

declare var  PIXELSIGNS :any;

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit,AfterViewInit {


  projects : Array<Project> = [];
  categories : Array<Category> = [];


  constructor(
    private portfolioService : PortfolioService,
    private dataService : DataService
  ) { }

  ngOnInit() {

    this.dataService.setTitle('Portfolio')

  }

  ngAfterViewInit(){
    
    this._getProjects();
    this._getCategories();

  }


  _getProjects = () => {
    
    this.portfolioService.getProjects()
    .then(( projects : Array<Project> ) => {

      projects.map(( project ) => {
        this.projects.push( new Project(project) )
      });
      
      PIXELSIGNS.initialize.portfolio();

    })
    .catch((error) => {
      console.log(error)
    })

  }

  _getCategories = () => {
    
    this.portfolioService.getCategories()
    .then(( categories : Array<Category> ) => {

      console.log('categories',categories)

      categories.map(( category ) => {
        this.categories.push( new Category(category) )
      })

    })
    .catch((error) => {
      console.log(error)
    })

  }

}
