class Category {

    _id : string;
    name : string;

    constructor(init : Category){
        Object.assign(this,init)
    }
}

export default Category;