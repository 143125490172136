import Category from './category.modal';

class Project {

    _id : string;
    name : string;
    date : string;
    descriptions : string;
    url : string;
    images : any;
    category : Category

    constructor(init : Project){
        Object.assign(this,init)
    }
}

export default Project;