import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { PortfolioDetailsComponent } from './portfolio/portfolio-details/portfolio-details.component';
import { ContactComponent } from './contact/contact.component';
import { ServiceDetailsComponent } from './services/service-details/service-details.component';


const routes: Routes = [

  {
    path : '',
    component : HomeComponent
  },
  {
    path : 'about',
    component : AboutComponent
  },
  {
    path : 'services',
    component : ServicesComponent
  },
  {
    path : 'services/:name/:id',
    component : ServiceDetailsComponent
  },
  {
    path : 'portfolio',
    component : PortfolioComponent
  },
  {
    path : 'project/:id',
    component : PortfolioDetailsComponent
  },
  {
    path : 'contact',
    component : ContactComponent
  },
  {
    path : 'contact/:slug',
    component : ContactComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
