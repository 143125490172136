import { Pipe, PipeTransform } from '@angular/core';
import { MASTER_NAME } from 'src/config/config';

@Pipe({
  name: 'domain'
})
export class DomainPipe implements PipeTransform {

  transform(value: any): any {
    return MASTER_NAME;
  }

}
