import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import Service from './service.modal';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {


  services : Array<Service> = [];
  showLoader : boolean = true;

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Services');

    this.getServices();

  }



  getServices(){

    this.dataService.__post('/get/services',{})
    .subscribe(
      (services : Array<Service>) => {
        this.services = services;
        this.showLoader = false;
      },
      (error) => {
        console.log(error)
      }
    )

  }

}
