class Testimonial {

    _id : string;
    name : string;
    review : any;
    
    constructor(init : Testimonial){
        Object.assign(this,init)
    }
}

export default Testimonial;