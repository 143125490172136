import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit() {
    this.dataService.setTitle('About');

    const htmlStr : String = `<!-- Put this code anywhere in the body of your page where you want the badge to show up. -->

    <div itemscope itemtype='http://schema.org/Person' class='fiverr-seller-widget' style='display: inline-block;'>
         <a itemprop='url' href=https://www.fiverr.com/design_3000 rel="nofollow" target="_blank" style='display: inline-block;'>
            <div class='fiverr-seller-content' id='fiverr-seller-widget-content-003363d9-b6d2-4a37-b7f9-503222d96a39' itemprop='contentURL' style='display: none;'></div>
            <div id='fiverr-widget-seller-data' style='display: none;'>
                <div itemprop='name' >design_3000</div>
                <div itemscope itemtype='http://schema.org/Organization'><span itemprop='name'>Fiverr</span></div>
                <div itemprop='jobtitle'>Seller</div>
                <div itemprop='description'>Hi, my name is tharana .i'm a professional graphic designer , I will design  logo, mockup , animated video ,logo intro and many more! Just ask and i will do it!
     </div>
            </div>
        </a>
    </div>
    
    <script id='fiverr-seller-widget-script-003363d9-b6d2-4a37-b7f9-503222d96a39' src='https://widgets.fiverr.com/api/v1/seller/design_3000?widget_id=003363d9-b6d2-4a37-b7f9-503222d96a39' data-config='{"category_name":"Graphics & Design"}' async='true' defer='true'></script>
    <!-- Put this code anywhere in the body of your page where you want the badge to show up. -->
    
    <div itemscope itemtype='http://schema.org/Person' class='fiverr-seller-widget' style='display: inline-block;'>
         <a itemprop='url' href=https://www.fiverr.com/design_3000 rel="nofollow" target="_blank" style='display: inline-block;'>
            <div class='fiverr-seller-content' id='fiverr-seller-widget-content-003363d9-b6d2-4a37-b7f9-503222d96a39' itemprop='contentURL' style='display: none;'></div>
            <div id='fiverr-widget-seller-data' style='display: none;'>
                <div itemprop='name' >design_3000</div>
                <div itemscope itemtype='http://schema.org/Organization'><span itemprop='name'>Fiverr</span></div>
                <div itemprop='jobtitle'>Seller</div>
                <div itemprop='description'>Hi, my name is tharana .i'm a professional graphic designer , I will design  logo, mockup , animated video ,logo intro and many more! Just ask and i will do it!
     </div>
            </div>
        </a>
    </div>
    
    <script id='fiverr-seller-widget-script-003363d9-b6d2-4a37-b7f9-503222d96a39' src='https://widgets.fiverr.com/api/v1/seller/design_3000?widget_id=003363d9-b6d2-4a37-b7f9-503222d96a39' data-config='{"category_name":"Graphics & Design"}' async='true' defer='true'></script>
    <!-- Put this code anywhere in the body of your page where you want the badge to show up. -->
    
    <div itemscope itemtype='http://schema.org/Person' class='fiverr-seller-widget' style='display: inline-block;'>
         <a itemprop='url' href=https://www.fiverr.com/design_3000 rel="nofollow" target="_blank" style='display: inline-block;'>
            <div class='fiverr-seller-content' id='fiverr-seller-widget-content-003363d9-b6d2-4a37-b7f9-503222d96a39' itemprop='contentURL' style='display: none;'></div>
            <div id='fiverr-widget-seller-data' style='display: none;'>
                <div itemprop='name' >design_3000</div>
                <div itemscope itemtype='http://schema.org/Organization'><span itemprop='name'>Fiverr</span></div>
                <div itemprop='jobtitle'>Seller</div>
                <div itemprop='description'>Hi, my name is tharana .i'm a professional graphic designer , I will design  logo, mockup , animated video ,logo intro and many more! Just ask and i will do it!
     </div>
            </div>
        </a>
    </div>
    
    <script id='fiverr-seller-widget-script-003363d9-b6d2-4a37-b7f9-503222d96a39' src='https://widgets.fiverr.com/api/v1/seller/design_3000?widget_id=003363d9-b6d2-4a37-b7f9-503222d96a39' data-config='{"category_name":"Graphics & Design"}' async='true' defer='true'></script>
    <!-- Put this code anywhere in the body of your page where you want the badge to show up. -->
    
    <div itemscope itemtype='http://schema.org/Person' class='fiverr-seller-widget' style='display: inline-block;'>
         <a itemprop='url' href=https://www.fiverr.com/design_3000 rel="nofollow" target="_blank" style='display: inline-block;'>
            <div class='fiverr-seller-content' id='fiverr-seller-widget-content-003363d9-b6d2-4a37-b7f9-503222d96a39' itemprop='contentURL' style='display: none;'></div>
            <div id='fiverr-widget-seller-data' style='display: none;'>
                <div itemprop='name' >design_3000</div>
                <div itemscope itemtype='http://schema.org/Organization'><span itemprop='name'>Fiverr</span></div>
                <div itemprop='jobtitle'>Seller</div>
                <div itemprop='description'>Hi, my name is tharana .i'm a professional graphic designer , I will design  logo, mockup , animated video ,logo intro and many more! Just ask and i will do it!
     </div>
            </div>
        </a>
    </div>
    
    <script id='fiverr-seller-widget-script-003363d9-b6d2-4a37-b7f9-503222d96a39' src='https://widgets.fiverr.com/api/v1/seller/design_3000?widget_id=003363d9-b6d2-4a37-b7f9-503222d96a39' data-config='{"category_name":"Graphics & Design"}' async='true' defer='true'></script>
    `
  }

}
