import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PortfolioService } from '../portfolio.service';
import { ActivatedRoute } from '@angular/router';
import Project from '../project.modal';
import { DataService } from 'src/app/shared/data.service';

@Component({
  selector: 'app-portfolio-details',
  templateUrl: './portfolio-details.component.html',
  styleUrls: ['./portfolio-details.component.css']
})
export class PortfolioDetailsComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navText: ['', ''],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  id : string;
  project : Project;

  constructor(
    private portfolioService : PortfolioService,
    private activatedRouter : ActivatedRoute,
    private dataService : DataService
  ) { }

  ngOnInit() {

    this.dataService.setTitle('Portfolio');

    this.id = this.activatedRouter.snapshot.paramMap.get('id');
    this._getProject(this.id);

  }

  _getProject = (id) => {

    this.portfolioService.getProject({_id : id})
    .then((project : Project) => {
      this.project = new Project(project);
      this.dataService.setTitle(project.name);
    })
    .catch((error) => {
      alert(error)
    })

  }

}
