import { Pipe, PipeTransform } from '@angular/core';
import { EXPERIENCE } from 'src/config/config';

@Pipe({
  name: 'experience'
})
export class ExperiencePipe implements PipeTransform {

  transform(value: any): any {
    return EXPERIENCE;
  }

}
