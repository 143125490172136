import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/data.service';
import Service from 'src/app/services/service.modal';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private dataService : DataService
  ) { }

  services : Array<Service> = [];

  ngOnInit() {
    this.getServices();
  }

  getServices(){

    this.dataService.__post('/get/services',{limit : 5})
    .subscribe(
      (services : Array<Service>) => {
        this.services = services;
      },
      (error) => {
        console.log(error)
      }
    )

  }

}
