import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../shared/data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  showMessage : boolean = false;

  @ViewChild('contactFroms',{static : true}) contactFroms : ElementRef;

  constructor(
    private dataService : DataService,
    private activatedRoute : ActivatedRoute
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Contact');

   if(this.activatedRoute.snapshot.paramMap.get('slug')){
      setTimeout(() => {
        const el : HTMLElement = this.contactFroms.nativeElement;
        el.scrollIntoView();
      },500)
   }

  }

  _contactForm(form){
    
    this.dataService.__post('/contact/form',form.value)
    .subscribe(
      (sent) => {
        this.showMessage = true;
        form.reset();
      },

      (error) => {
        alert(error)
      }
    )

  }

}
